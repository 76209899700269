import React from "react"
import { graphql } from "gatsby"
import AssistanceComponent from 'confirm-assistance-component'
import { Fade } from 'react-reveal'


import Layout from "../components/layout"
import SEO from "../components/seo"

const RSVP = ({data}) => {
  let mainColor = "#382F43";
  let titleStyle = {
    backgroundColor: mainColor,
    color: "#ffffff",
    padding: "0.5em"
  }

  let ass;

  if(typeof window !== `undefined`){
    ass = <AssistanceComponent MainColor="#222222" TextColor="#222222"/>;
  }

  return (
    <Layout Color={mainColor} Imagen={data.coverImage.childImageSharp.fluid} Titulo="R.S.V.P">
      <SEO title="RSVP" />
      <div className="Page__Text RSVP__Text">
        <p>¡CADA UNO DE USTEDES ES MUY IMPORTANTE EN NUESTRAS VIDAS!</p>
        <p>
          Por lo mismo, nos haría muy muy felices que todos nos pudieran acompañar. También estamos conscientes de la lejanía de muchos o que pueden existir diversas circunstancias que dificulten el ir (ya que será fuera de la CDMX como ya saben), pero si nos pudieran confirmar su asistencia antes del 1 de junio (o antes si les es posible) lo agradeceríamos muchísimo.<br/>
        </p>
        <p>
          Una abrazo grande a todos.
        </p>
        <h4 style={{
          color: mainColor,
          margin: "10vh 0"
        }}>FECHA LÍMITE DE CONFIRMACIÓN: 1 DE JUNIO 2019</h4>
        {ass}
      </div>
      <div className="Page__Text Iconos__Boda">
        <h4 style={titleStyle}>Hoteles y Tarifas Preferenciales</h4>

        <div className="responsive-container">
          <Fade left>
            <div className="size-2">
              <a href="https://hoteldelasoledad.com/" target="__blank"><img srcSet={data.soledadLogo.childImageSharp.fluid.srcSet}
                sizes={data.soledadLogo.childImageSharp.fluid.sizes}
                src={data.soledadLogo.childImageSharp.fluid.src} alt="Logo Soledad" />
              <h6>Hotel de la Soledad</h6></a>
              <a href="https://res.cloudinary.com/rafaremo/image/upload/v1558072968/assets-rafa-mon/HOTEL_DE_LA_SOLEDAD_RAFA_Y_MON.pdf" target="__blank"><h5>Descarga Info Completa<br/><small>(Tarifas, ¿Que Incluye?, etc..)</small></h5></a>
            </div>
          </Fade>
          <Fade right>
            <div className="size-2">
              <a href="https://www.canteradiezhotel.com/" target="__blank"><img srcSet={data.canteraLogo.childImageSharp.fluid.srcSet}
                sizes={data.canteraLogo.childImageSharp.fluid.sizes}
                src={data.canteraLogo.childImageSharp.fluid.src} alt="Logo Cantera 10" />
              <h6>Hotel Cantera 10</h6></a>
              <a href="https://res.cloudinary.com/rafaremo/image/upload/v1558072126/assets-rafa-mon/CANTERA_10_RAFA_Y_MON_BIEN.pdf" target="__blank"><h5>Descarga Info Completa<br/><small>(Tarifas, ¿Que Incluye?, etc..)</small></h5></a>
            </div>
          </Fade>
          <h5 className="size-1">CÓDIGO PARA TARIFA PREFERENCIAL:<br/>BODA MÓNICA Y RAFAEL</h5>
          <p className="size-1"><small>Si prefieres otro hotel o un Airbnb, recomendamos que sea cerca del centro de Morelia, ya que la boda se realizará ahí.</small></p>
        </div>
      </div>
    </Layout>
  )
}

export default RSVP

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "central.JPG" }){
      childImageSharp {
        fluid(maxWidth: 1920 quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    canteraLogo: file(relativePath: { eq: "cantera.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    soledadLogo: file(relativePath: { eq: "soledad.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;